(function ($, DGS, moment) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    $(".component.related-blogs").each(function () {
      var $this = $(this);
      var $relatedPosts = $(".component-content ul > li > div", $this);
      $relatedPosts.each(function (i, relatedPost) {
        var $link = $(".news-link .link", $(relatedPost)).last();
        $(relatedPost).click(function (e) {
          if (!$(e.target).hasClass("link")) {
            // jQuery click does not work on links
            $link[0].click();
          }
        });
      });
      setTimeout(function () {
        $this.addClass("component-ready animate");
      }, 500);

      //if date is unset - hide
      $(".news-date").each(function () {
        var dateText = $(this).text().trim();
        if (/^01[\/-]01[\/-]0*1$/.test(dateText)) {
          $(this).css("display", "none");
        }
      });
      $("ul li", $(this)).each(function () {
        // Now change the date format
        var $newsDate = $(".news-date", $(this));
        var $newsTextContent = $(".news-text", $(this));
        $(".news-text", $(this)).html("<p>" + $newsTextContent.text() + "</p>");

        // Moves the date to after the image
        $newsDate.insertAfter($(".news-image", $(this)), null);
      });
      var currentUrlItems = window.location.href.split("/");
      currentUrlItems.pop();
    });
    $(".component.related-blogs a").each(function () {
      var hrefAttr = $(this).attr("href");
      var location = window.location.href.replace(/^(?:\/\/|[^/]+)*\//, "");
      if (hrefAttr === location) {
        $(this).parent("li").remove();
      }
    });
    if ($(".component.related-blogs .component-content .related-title").length && $(".component.rich-text.related-title").length) {
      $(".component.rich-text.related-title").hide();
    }
    if (!$(".component.related-blogs ul > li").length) {
      $(".component.related-blogs").hide();
    }
  });
})(jQuery, window.DGS, window.moment);