/**
 * Created by mady on 03-10-2016.
 */
(function ($) {
  "use strict";

  var getConfig = function getConfig($component) {
      var $config = $('script[type="application/json"]', $component);
      try {
        if ($config.length) return JSON.parse($config.html());
      } catch (e) {
        return {};
      }
    },
    showProfile = function showProfile($profile, onComplete) {
      $profile.show();
      var $innerContainer = $('.login-spot-profile-inner-container', $profile);
      TweenMax.set($innerContainer, {
        "height": "auto"
      });
      TweenMax.fromTo($profile, 0.2, {
        alpha: 0
      }, {
        alpha: 1,
        ease: Power0.easeNone
      });
      TweenMax.from($innerContainer, 0.6, {
        height: 0,
        ease: Power0.easeNone,
        onComplete: onComplete
      });
    },
    hideProfile = function hideProfile($profile, _onComplete) {
      var $innerContainer = $('.login-spot-profile-inner-container', $profile);
      TweenMax.to($innerContainer, 0.4, {
        height: 0,
        ease: Power0.easeNone
      });
      TweenMax.to($profile, 0.2, {
        alpha: 0,
        ease: Power0.easeNone,
        delay: 0.2,
        onComplete: function onComplete() {
          $profile.hide();
          _onComplete.apply();
        }
      });
    },
    turnOffHandlers = function turnOffHandlers($profile) {
      $('.login-spot-profile-close', $profile).off('click');
      $('body').off('click');
    };
  window.DGS.OnLoad.getInstance().register(function () {
    $('#wrapper > #header .component.login-spot').each(function () {
      var $this = $(this),
        $loggedInContainer = $('.logged-in', $this),
        $navList = $('#header .component.link-list.nav .component-content ul'),
        config = getConfig($this),
        navigation,
        $nameButton = $('.fill-name', $this);
      if ($loggedInContainer.length) {
        navigation = config.Navigation;
        if (navigation && $navList.length) {
          navigation.forEach(function (element) {
            $navList.append('<li class="login-spot"><a href="' + element.Href + '" title="' + element.Label + '">' + element.Label + '</a></li>');
          });
        }
      }
      if ($navList.length) {
        $('.login-spot-profile-link', $this).each(function () {
          var $this = $(this),
            href = $this.attr('href'),
            label = $('.login-spot-profile-link-label', $this).text();
          $navList.append('<li class="login-spot mobile-only"><a href="' + href + '" title="' + label + '">' + label + '</a></li>');
        });
      }
      $nameButton.click(function (evt) {
        evt.preventDefault();
        var $profileContainer = $('.login-spot-profile-outer-container', $this);
        if (!$profileContainer.is(":visible")) {
          $nameButton.addClass('active');
          showProfile($profileContainer, function () {
            var id = $profileContainer.attr('id');
            if (!id) {
              id = "profile" + Math.round(Math.random() * 10000);
              $profileContainer.attr('id', id);
            }
            $('.login-spot-profile-close', $profileContainer).click(function () {
              hideProfile($profileContainer, function () {
                turnOffHandlers($profileContainer);
              });
            });
            $('body').click(function (e) {
              if (e.target.id == id || $(e.target).parents("#" + id).size()) {
                // click inside div. DO NOTHING
              } else {
                // click outside div. Close div.
                hideProfile($profileContainer, function () {
                  turnOffHandlers($profileContainer);
                });
              }
            });
          });
        } else {
          $nameButton.removeClass('active');
          hideProfile($profileContainer, function () {
            turnOffHandlers($profileContainer);
          });
        }
      });
    });
  });
  window.DGS.Scroller.getInstance().register(function (scrollTop) {
    $('#wrapper > #header .component.login-spot').each(function () {
      var $this = $(this),
        $profileContainer = $('.login-spot-profile-outer-container', $this);
      if ($profileContainer.is(":visible")) {
        if (scrollTop > $profileContainer.position().top * 2) {
          hideProfile($profileContainer, function () {
            turnOffHandlers($profileContainer);
          });
        }
      }
    });
  });
})(jQuery);