window.DGS.OnLoad.getInstance().register(function () {
  var accessOnEmailFormContainer = $(".access-on-email-form-container");
  var componentContent = accessOnEmailFormContainer.find(".component-content");
  var closeBtn = $("<span id='close-btn'></span>");
  var formErrorMsgs = accessOnEmailFormContainer.find(".error-message");
  var accessOnEmailSuccessMsg = accessOnEmailFormContainer.find("#access-on-email-success");
  var accessOnEmailErrorMsg = accessOnEmailFormContainer.find("#access-on-email-error");
  var refreshPage = accessOnEmailFormContainer.find("#refresh-page");
  var accessOnEmailForm = $("#access-on-email-form");
  var accessOnEmailSubmit = accessOnEmailForm.find("button.submit");
  var input = $("#access-on-email-form input");
  var allInputs = accessOnEmailForm.find("input");

  // initial setup
  accessOnEmailFormContainer.prependTo("body");
  accessOnEmailFormContainer.hide();
  componentContent.wrapInner("<div class='popup-inner'></div>").wrapInner("<div class='popup-outer'></div>");
  closeBtn.appendTo(accessOnEmailFormContainer.find(".popup-inner"));
  formErrorMsgs.hide();
  accessOnEmailSuccessMsg.hide();

  // event handlers
  // handle original link click
  $(".access-on-email").on("click", function (event) {
    var isEmailProvided = localStorage.getItem("email-provided");
    if (isEmailProvided) {
      accessOnEmailFormContainer.remove();
      return;
    } else {
      event.preventDefault();
      // make sure to add only one clicked link to success message
      accessOnEmailSuccessMsg.find("#download-link").remove();
      var originalLink = $(this).parent();
      var downloadLink = constructDownloadLink(originalLink);
      accessOnEmailSuccessMsg.append(downloadLink);
      accessOnEmailErrorMsg.hide();
      accessOnEmailFormContainer.show();
      accessOnEmailForm.show();
    }
  });

  // handle form submition
  accessOnEmailSubmit.on("click", function () {
    var formData = getFormData(allInputs);
    if (formData) {
      var formDataJson = JSON.stringify(formData);
      sendFormData(formDataJson, accessOnEmailForm, accessOnEmailSuccessMsg, accessOnEmailErrorMsg);
    }
  });
  input.on("focusout", function () {
    if (validateInput($(this))) {
      $(this).next(".error-message").hide();
    } else {
      $(this).next(".error-message").show();
    }
  });
  refreshPage.on("click", function () {
    window.location.reload(true);
  });
  closeBtn.on("click", function () {
    accessOnEmailFormContainer.hide();
  });
  accessOnEmailSuccessMsg.on("click", "#download-link", function () {
    accessOnEmailFormContainer.remove();
  });

  // hide popup when clicked outside it
  accessOnEmailFormContainer.on("click", function (event) {
    if ($(event.target).closest(".popup-outer").length === 0) {
      accessOnEmailFormContainer.hide();
    }
  });
});

// helpers
var getFormData = function getFormData(inputs) {
  var formData = {};
  inputs.each(function () {
    if (validateInput($(this))) {
      formData[this.name] = $(this).val();
      $(this).next(".error-message").hide();
    } else {
      $(this).next(".error-message").show();
    }
  });

  // check if all inputs are valid
  if (inputs.length === Object.keys(formData).length) {
    return formData;
  } else {
    return null;
  }
};
var sendFormData = function sendFormData(formData, formElement, successMsgElement, errorMsgElement) {
  $.ajax({
    type: "POST",
    url: "https://www.diatec-diagnostics.jp/webservices/crmdiatec.svc/post",
    data: formData,
    async: true,
    dataType: "json",
    encode: true,
    success: function success(response, textStatus, jqXHR) {
      console.log(response);
      formElement.hide();
      successMsgElement.show();
      localStorage.setItem("email-provided", true);

      // clear form
      formElement.find("input").each(function () {
        $(this).val("");
      });
    },
    error: function error(jqXHR, textStatus, errorThrown) {
      formElement.hide();
      errorMsgElement.show();
      console.log(jqXHR);
      console.log(textStatus);
      console.log(errorThrown);
    }
  });
};
var constructDownloadLink = function constructDownloadLink(originalLink) {
  var linkText = originalLink.text();
  var linkUrl = originalLink.attr("href");
  var downloadLink = $("<a/>", {
    id: "download-link",
    href: linkUrl,
    html: linkText,
    target: "_blank"
  });
  return downloadLink;
};
var validateInput = function validateInput(input) {
  var inputType = input.attr("type");
  var emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  switch (inputType) {
    case "text":
      return input.val().length;
    case "email":
      return emailRegex.test(input.val());
    default:
      return input.val().length;
  }
};