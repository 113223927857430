require('core-js');require('regenerator-runtime/runtime');// FOUNDATION
// require("../../../wdh-foundation/sms/sms-api.js");

import $ from "jquery";
window.jQuery = $;
window.$ = $;
import { TweenMax } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
require("@demant/legacy/custom-scroll");
require("../../../wdh-foundation/dateformat/dateformat.js");
require("../../../wdh-foundation/dgs/dgs-api.js");
require("../../../wdh-foundation/dgs/dgs-events.js");
require("@demant/megamenu");
require("../../../wdh-foundation/dgs-utils/dgs-utils.js");
require("@demant/burgermenu");
require("@demant/legacy/googlemaps");
require("iframe-resizer");
require("jquery-nice-select/js/jquery.nice-select.min.js");
require("jquery-ui-dist/jquery-ui.min.js");
require("lightgallery/src/js/lightgallery.js");
require("@demant/legacy/lunametrics");
require("jquery-bridget");
require("masonry-layout");
require("moment");
require("slick-carousel/slick/slick.min.js");

// FEATURE
require('./feature/alignElements.js');
require("./feature/component-a-data-hierarchy.js");
require("./feature/component-b-data-hierarchy-appframe-component.js");
require("./feature/component-b-data-hierarchy-hubspot.js");
require("./feature/component-b-data-hierarchy-multi-step.js");
require("./feature/component-b-data-hierarchy-resource-center.js");
require("./feature/component-data-hierarchy-dataset.js");
require("./feature/component-link-list.js");
require("./feature/component-search.js");
require("./feature/component-video-spot.js");
require("./feature/component-box-spot.js");
require("./feature/component-cookies.js");
require("./feature/component-flexspot-colorful-tiles.js");
require("./feature/component-flexspot-pardot-form.js");
require("./feature/component-form.js");
require("./feature/component-iframe.js");
require("./feature/component-intro-banner.js");
require("./feature/component-news-list-spot.js");
require("./feature/component-option-spot.js");
require("./feature/component-image-spot.js");
require("./feature/component-text-image-spot.js");
require("./feature/component-predefined-login-spot.js");
require("./feature/component-accordion-spot");
require("./feature/component-slider");
require("./feature/component-blog-list");
require("./feature/component-blog");

// PROJECT
require("./project/popup.js");
require("./project/base-main-custom.js");
require("./project/tracking-gtm.js");
require("./project/component-singlesignon.js");
require("./project/custom-a-login-spot.js");
require("./project/custom-datepicker.js");
require("./project/custom-footer.js");
require("./project/custom-header.js");
require("./project/custom-order-details.js");
require("./project/custom-select.js");
require("./project/login.js");
require("./project/main.js");
require("./feature/language-selector-spot");
require("./project/custom-jquery-nice-select.js");
require("./project/custom-access-form-popup.js");
require("@demant/wdh-gdpr/lib/default.implementation.js");
require("./feature/component-blog");
require("./feature/component-blog-related");