(function ($) {
  'use strict';

  window.DGS.OnContentLoaded.getInstance().register(function () {
    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
    $("li.option").each(function () {
      var currentProduct = $(this).attr("data-value");
      var products = getCookie("products");
      var deProducts = products.split("|");
      var inputField = $('span.current');
      var inputFieldText = inputField.val();
      if (products.length === 0) {
        console.log("nope");
      } else {
        console.log(deProducts);
        if (deProducts.some(function (v) {
          return currentProduct.includes(v);
        })) {
          var currentProductId = $(this).attr("data-value");
          $(this).addClass("match");
          $('.match :checkbox').prop('checked', true);
          if (inputFieldText.match(currentProductId)) {
            console.log("test");
          } else {
            inputField.text(function (n, c) {
              return c + currentProductId + ", ";
            });
          }
        } else {}
      }
    });
    $('.component.form.mobile-accordion').each(function () {
      var thisForm = $(this),
        $checkboxListLabels = thisForm.find('.scfCheckBoxListLabel');
      thisForm.find('.scfCheckBoxListGeneralPanel').addClass("is-collapsed");
      $checkboxListLabels.append("<span class='arrow'>");
      thisForm.find('.scfCheckBoxListGeneralPanel').each(function () {
        if (window.matchMedia("(max-width: 767px)").matches) {
          var $req = $(this).next(".scfRequired");
          if ($req.length) {
            $(this).prev(".scfCheckBoxListLabel").addClass("is-required");
            $req.hide();
          }
        }
      });
      $checkboxListLabels.click(function (e) {
        var $checkboxListLabel = $(this),
          $checkboxList = $checkboxListLabel.next(".scfCheckBoxListGeneralPanel");
        if ($checkboxListLabel.hasClass("is-open")) {
          $checkboxListLabel.removeClass("is-open");
          $checkboxList.addClass("is-collapsed");
        } else {
          $checkboxListLabel.addClass("is-open");
          $checkboxList.removeClass("is-collapsed");
        }
      });
    });
  });
  var validation = $('.scfValidationSummary');
  var wasValidated = validation.children().length;
  var scrollToForm = function scrollToForm() {
    var scrollTop = $(".scfForm").offset().top;
    if (window.matchMedia('(max-width: 1023px)').matches) {
      scrollTop -= $('#header').outerHeight();
    }
    $("body,html").animate({
      scrollTop: scrollTop
    }, 800);
  };
  if (wasValidated || !validation.length) {
    setTimeout(function () {
      $('.accordion-form .scfForm').show();
      scrollToForm();
    }, 500);
  }
  $(document).on('click', '.show-form-button', function (e) {
    e.preventDefault();
    $('.accordion-form .scfForm').toggle();
    if ($('.accordion-form .scfForm').css('display') === 'block') {
      scrollToForm();
    }
  });
})(jQuery);