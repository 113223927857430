(function ($, DGS, moment) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    $(".component.blog-list").each(function () {
      $("#blog-list li", $(this)).each(function () {
        var $newsDate = $(".news-date", $(this));
        $newsDate.insertAfter($(".news-image", $(this)), null);
      });
    });
  });
  var articles = $(".bloglist ul#blog-list li");
  var blogList = $(".component-content.bloglist");
  $(".component.blog-list").addClass("pagination-ready");
  var url = new URL(window.location.href);
  var currentPage = Number(url.searchParams.get("page")) || 1;
  var maxArticlesPerPage = $(window).width() <= 768 ? 3 : 6;
  var setUrlHistory = function setUrlHistory() {
    url.searchParams.has("page") ? url.searchParams.set("page", currentPage) : url.searchParams.append("page", currentPage);
    window.history.replaceState("bloglist-page", "", url.search);
  };
  var paginateArticles = function paginateArticles() {
    if (articles.length > maxArticlesPerPage) {
      articles.addClass("hide-from-list");
      for (var i = 0; i < maxArticlesPerPage * currentPage; i++) {
        $(articles[i]).removeClass("hide-from-list");
      }
    }
    if (maxArticlesPerPage * currentPage >= articles.length) {
      $(".addMoreButton").remove();
    }
  };
  var viewMoreButton = $('<button class="addMoreButton" type="button">Load more</button>');
  blogList.append(viewMoreButton);
  viewMoreButton.on("click", function (e) {
    e.preventDefault();
    currentPage += 1;
    setUrlHistory();
    paginateArticles();
  });
  paginateArticles();
  DGS.Resizer.getInstance().register(function () {
    if ($(window).width() <= 768 && maxArticlesPerPage === 6) {
      maxArticlesPerPage = 4;
      paginateArticles();
    }
    if ($(window).width() > 768 && maxArticlesPerPage === 3) {
      maxArticlesPerPage = 6;
      paginateArticles();
    }
    var row = 1;
    if ($(window).width() > 1200) {
      row = 4;
    } else if ($(window).width() > 991) {
      row = 2;
    }
    DGS.alignElements(row, "#blog-list > li", [".news-title", ".news-text"]);
  });
})(jQuery, window.DGS, window.moment);