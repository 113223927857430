(function ($) {
  $.fn.niceSelect = function (method) {
    // Methods
    if (typeof method == "string") {
      if (method == "update") {
        this.each(function () {
          var $select = $(this);
          var $dropdown = $(this).next(".nice-select");
          var open = $dropdown.hasClass("open");
          if ($dropdown.length) {
            $dropdown.remove();
            create_nice_select($select);
            if (open) {
              $select.next().trigger("click");
            }
          }
        });
      } else if (method == "destroy") {
        this.each(function () {
          var $select = $(this);
          var $dropdown = $(this).next(".nice-select");
          if ($dropdown.length) {
            $dropdown.remove();
            $select.css("display", "");
          }
        });
        if ($(".nice-select").length == 0) {
          $(document).off(".nice_select");
        }
      } else {
        console.log('Method "' + method + '" does not exist.');
      }
      return this;
    }

    // Hide native select
    this.hide();

    // Create custom markup
    this.each(function () {
      var $select = $(this);
      if (!$select.next().hasClass("nice-select")) {
        create_nice_select($select);
      }
    });
    function create_nice_select($select) {
      $select.after($("<div></div>").addClass("nice-select").addClass($select.attr("class") || "").addClass($select.attr("disabled") ? "disabled" : "").attr("tabindex", $select.attr("disabled") ? null : "0").html('<span class="current"></span><ul class="list"></ul>'));

      //List box to multiple choice checkbox dropdown - Multiple choice
      if ($select.closest(".scfListBoxGeneralPanel").parent().hasClass("scfListBox-multiple")) {
        $select.closest(".scfListBoxGeneralPanel").find(".nice-select").addClass("nice-select-multiple");
      }
      var $dropdown = $select.next();
      var $options = $select.find("option");
      var $selected = $select.find("option:selected");
      if ($dropdown.hasClass("nice-select-multiple")) {
        var selectedvalue = "";
        $selected.each(function () {
          selectedvalue = selectedvalue + $(this).val() + ",";
        });
        $dropdown.find(".current").html(selectedvalue);
      } else {
        $dropdown.find(".current").html($selected.data("display") || $selected.text());
      }
      $options.each(function (i) {
        var $option = $(this);
        var display = $option.data("display");
        if ($option.text()) {
          $dropdown.find("ul").append($("<li></li>").attr("data-value", $option.val()).attr("data-display", display || null).addClass("option" + ($option.is(":selected") ? " selected" : "") + ($option.is(":disabled") ? " disabled" : "")).html($option.text()));
        }
      });

      //Insert checkbox to listbox options - Multiple choice
      if ($dropdown.hasClass("nice-select-multiple")) {
        $dropdown.find("ul li").each(function (i) {
          if ($(this).attr("data-value").length != 0) {
            $(this).html($('<input type="checkbox" value ="' + $(this).attr("data-value") + '">' + "<label>" + $(this).attr("data-value") + "</label>"));
            $(this).hasClass("selected") ? $(this).find('input[type="checkbox"]').prop("checked", "checked") : "";
          }
        });
      }
    }

    /* Event listeners */

    // Unbind existing events in case that the plugin has been initialized before
    $(document).off(".nice_select");

    // Open/close
    $(document).on("click.nice_select", ".nice-select:not(.nice-select-multiple)", function (event) {
      var $dropdown = $(this);
      $(".nice-select").not($dropdown).removeClass("open");
      $dropdown.toggleClass("open");
      if ($dropdown.hasClass("open")) {
        $dropdown.find(".option");
        $dropdown.find(".focus").removeClass("focus");
        $dropdown.find(".selected").addClass("focus");
      } else {
        $dropdown.focus();
      }
    });

    //Open /Close multiple choice listbox - Multiple choice
    $(document).on("click.nice_select", ".nice-select-multiple", function (event) {
      var $dropdown = $(this);
      if ($dropdown.hasClass("open")) {
        $dropdown.removeClass("open");
      } else {
        $dropdown.toggleClass("open");
      }
      if ($dropdown.hasClass("open")) {
        $dropdown.find(".option");
        $dropdown.find(".focus").removeClass("focus");
        $dropdown.find(".selected").addClass("focus");
      } else {
        $dropdown.focus();
      }
    }).on("click", ".nice-select-multiple .list", function (e) {
      e.stopPropagation();
    });
    // Close when clicking outside
    $(document).on("click.nice_select", function (event) {
      if ($(event.target).closest(".nice-select").length === 0) {
        $(".nice-select").removeClass("open").find(".option");
      }
    });
    // Option click
    $(document).on("click.nice_select", ".nice-select .option:not(.disabled)", function (event) {
      var $option = $(this);
      var $dropdown = $option.closest(".nice-select");
      if (!$dropdown.hasClass("nice-select-multiple")) {
        var text = $option.data("display") || $option.text();
        $dropdown.find(".current").text(text);
      }
      if ($dropdown.hasClass("nice-select-multiple")) {
        $dropdown.prev("select").val($dropdown.find(".current").text().split(",")).trigger("change");
      } else {
        $dropdown.find(".selected").removeClass("selected");
        $option.addClass("selected");
        $dropdown.prev("select").val($option.data("value")).trigger("change");
      }
    });

    //Multiple select click - Multiple choice
    $(document).ready(function () {
      var $option = $(this);
      var $dropdown = $option.closest(".nice-select-multiple");
      $(".nice-select-multiple .list li").on("click", function () {
        var $spancurrent = $(this).closest(".nice-select-multiple").find(".current"),
          $currentcheckbox = $(this).find('input[type="checkbox"]');
        $currentcheckbox.prop("checked", !$currentcheckbox.prop("checked"));
        var title = $currentcheckbox.val(),
          title = $currentcheckbox.val() + ",",
          update_text = $spancurrent.text() + title;
        if ($currentcheckbox.is(":checked")) {
          $spancurrent.text(update_text);
          $spancurrent.attr("title", update_text);
        } else {
          title = $spancurrent.text().replace(title, "");
          $spancurrent.text(title);
          $spancurrent.attr("title", title);
        }
      });
    });

    // Keyboard events
    $(document).on("keydown.nice_select", ".nice-select", function (event) {
      var $dropdown = $(this);
      var $focused_option = $($dropdown.find(".focus") || $dropdown.find(".list .option.selected"));

      // Space or Enter
      if (event.keyCode == 32 || event.keyCode == 13) {
        if ($dropdown.hasClass("open")) {
          $focused_option.trigger("click");
        } else {
          $dropdown.trigger("click");
        }
        return false;
        // Down
      } else if (event.keyCode == 40) {
        if (!$dropdown.hasClass("open")) {
          $dropdown.trigger("click");
        } else {
          var $next = $focused_option.nextAll(".option:not(.disabled)").first();
          if ($next.length > 0) {
            $dropdown.find(".focus").removeClass("focus");
            $next.addClass("focus");
          }
        }
        return false;
        // Up
      } else if (event.keyCode == 38) {
        if (!$dropdown.hasClass("open")) {
          $dropdown.trigger("click");
        } else {
          var $prev = $focused_option.prevAll(".option:not(.disabled)").first();
          if ($prev.length > 0) {
            $dropdown.find(".focus").removeClass("focus");
            $prev.addClass("focus");
          }
        }
        return false;
        // Esc
      } else if (event.keyCode == 27) {
        if ($dropdown.hasClass("open")) {
          $dropdown.trigger("click");
        }
        // Tab
      } else if (event.keyCode == 9) {
        if ($dropdown.hasClass("open")) {
          return false;
        }
      }
    });

    // Detect CSS pointer-events support, for IE <= 10. From Modernizr.
    var style = document.createElement("a").style;
    style.cssText = "pointer-events:auto";
    if (style.pointerEvents !== "auto") {
      $("html").addClass("no-csspointerevents");
    }
    return this;
  };
})(jQuery);