(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.option-spot').each(function () {
      var $this = $(this);
      window.DGS.Justify.getInstance().apply($('ul', $this), 'li');
    });
    $(".optionspot-click-full .optionspotelement").click(function () {
      var anchorElement = $(this).find(".option-spot-element-icon").find("a");
      if (anchorElement.length > 0 && anchorElement.attr("href").length > 1) window.location = anchorElement.attr("href");
    });
  });
})(jQuery);