//* This flexspot component needs to have 'medical-alert' class
window.DGS.OnLoad.getInstance().register(function () {
  var buildPopup = function buildPopup(selector) {
    var content = selector.find('.element__item__text[data-tag="popup-content"]').html();
    var warning = selector.find('.element__item__text[data-tag="popup-warning"]').html() || "";
    var checkBox = selector.find('.element__item__text[data-tag="popup-checkbox"]').prepend('<input type="checkbox" class="popup-checkbox" />').html() || "";
    var buttonYes = selector.find('.element__item__link[data-tag="button-yes"] a').eq(0).addClass("popup-btn yes").prop("outerHTML") || '<a class="popup-btn yes">Yes</a>';
    var buttonNo = selector.find('.element__item__link[data-tag="button-no"] a').eq(0).addClass("popup-btn no").prop("outerHTML") || '<a class="popup-btn no">No</a>';
    var popupHTML = "\n    <div class=\"once-per-session popup\">\n      <div class=\"popup-outer\">\n        <div class=\"popup-inner\">\n          ".concat(content, "\n          ").concat(checkBox && "<label class=\"popup-checkbox-content\">".concat(checkBox, "</label>"), "\n          ").concat(warning && "<p class=\"popup-warning warning-hide\">".concat(warning, "</p>"), "\n          <div class=\"popup-buttons\">").concat(buttonNo, " ").concat(buttonYes, "</div>\n        </div>\n      </div>\n    </div>");
    $("body").append(popupHTML);
  };
  var removePopup = function removePopup() {
    $(".once-per-session.popup").remove();
  };
  var lsMedicalAlert = localStorage.getItem("medical-alert");
  var popup = $(".flex-spot.medical-alert");
  if (!lsMedicalAlert && popup.length) {
    buildPopup(popup);
  }

  //if checkbox exists disable the yes button
  checkboxExist = Boolean(document.querySelector(".popup-checkbox-content"));
  $(function () {
    if (checkboxExist) {
      $(".yes").addClass("disabled-link");
    }
  });
  $(document).on("click", ".popup-checkbox", function (e) {
    $(this).is(":checked") ? $(".yes").removeClass("disabled-link") : $(".yes").addClass("disabled-link");
  });
  $(document).on("click", ".popup-btn", function (e) {
    var _that = $(this);
    var href = _that.attr("href");
    var emptyLink = !href || href === "http://" || href === "https://"; //* If href in s9 link is empty, s9 give us href with only 'http://'

    var isTheSamePage = window.location.href.substring(window.location.href.lastIndexOf("/")) === href; //* Don't redirect if link is the same as url

    if (_that.hasClass("yes") && $(".popup-checkbox").is(":checked") || _that.hasClass("yes") && checkboxExist === false) {
      localStorage.setItem("medical-alert", true);
      if (isTheSamePage || emptyLink) {
        e.preventDefault();
        removePopup();
      }
    } else if (_that.hasClass("no") && emptyLink) {
      $(".popup-warning").removeClass("warning-hide");
      e.preventDefault();
    }
  });
});