import { resolveImageSrc } from '@demant/wdh';
"use strict";
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    //check if page is in edit mode
    var isInEditMode = $("body").hasClass("mode-editing");
    //prevent clicks on readmore links in editmode
    editModeEventsForReadmore();
    function setHeight($this) {
      var $imgContainer = $('.text-image-spot-image > img', $this),
        $textContainer = $('.text-image-spot-text', $this),
        imgH = $imgContainer.height(),
        //imgIsComplete = $imgContainer[0].complete,
        textH = $textContainer.height(),
        maxH = Math.max(imgH, textH),
        isTextForceHeight = $this.hasClass('tforceheight'),
        maxH = isTextForceHeight ? textH : maxH,
        $textOuterContainer = $('.text-image-spot-text-container', $this),
        $imgOuterContainer = $('.text-image-spot-image', $this),
        top = $textOuterContainer.css('top'),
        bottom = $textOuterContainer.css('bottom'),
        pad = parseInt(top) + parseInt(bottom);
      pad = pad < 0 ? 0 : pad;
      if ($this.hasClass('pull-up')) {
        var negativeM = $this.children().css('margin-top');
        negativeM = Number(negativeM.substr(0, negativeM.length - 2));
        maxH = maxH + negativeM;
      }
      if (maxH > 50 || $this.hasClass('pull-up')) {
        $this.css('min-height', maxH + pad + 'px');
      }
      if (($this.hasClass('above-right') || $this.hasClass('above-left')) && $('body').width() > 599) {
        $this.css('margin-top', '-' + $this.height() + 'px');
      }
      if (isTextForceHeight && imgH <= textH + pad) {
        $this.addClass('small-image');
      } else {
        $this.removeClass('small-image');
      }
    }
    $('.text-image-spot:not(.version2)').each(function () {
      var $this = $(this);
      window.DGS.WaitForLoad.getInstance().register($('.text-image-spot', $this));
      window.DGS.OnContentLoaded.getInstance().register(function () {
        setHeight($this);
        window.DGS.LoadComplete.getInstance().register($this, function () {});
      });
    });
    window.DGS.Resizer.getInstance().register(function () {
      $('.text-image-spot:not(.version2)').each(function () {
        var $this = $(this);
        setHeight($this);
      });
    });
    $('.component.text-image-spot').each(function () {
      var $this = $(this),
        $imgLink = $('.text-image-spot-image-container a', $this),
        $readMore = $('.text-image-spot-readmore', $this),
        embedCode;
      if ($imgLink.length) {
        embedCode = $imgLink.attr('data-embed');
        DGS.Event.getInstance().trigger('link-embed-code-detected');
      }
      if ($readMore.length && !isInEditMode) {
        if (!$this.hasClass("readmore")) {
          $this.addClass("readmore"); //last-minute add-on to replace style variation, requested by PHEY
        }
      }
    });
    if (!isInEditMode) {
      $('.component.text-image-spot.readmore').each(function () {
        var $this = $(this),
          $openLink = $('.text-image-spot-readmore-open', $this),
          $lessLink = $('.text-image-spot-readmore-less', $this),
          $text = $('.text-image-spot-text', $this),
          $readMore = $('.text-image-spot-readmore', $this),
          $closeLink = $(".text-image-spot-readmore-close", $readMore),
          $moreText = $(".text-image-spot-readmore-text", $readMore);
        $openLink.appendTo($text);
        $lessLink.appendTo($text);
        $readMore.insertAfter($this);
        $closeLink.appendTo($moreText);
        $openLink.click(function (e) {
          e.preventDefault();
          expandSection($this, $readMore);
        });
        $lessLink.click(function (e) {
          e.preventDefault();
          collapseSection($this, $readMore);
        });
        $closeLink.click(function (e) {
          e.preventDefault();
          collapseSection($this, $readMore);
        });
      });
    }
    function collapseSection(parent, element) {
      var scrollTop = parent.offset().top,
        headerHeight = getHeaderHeight();
      $("html, body").animate({
        scrollTop: scrollTop - parseInt(headerHeight)
      }, 300, function () {
        element.animate({
          height: 0
        }, 100, function () {
          parent.removeClass("readmore--open");
        });
      });
    }
    function expandSection(parent, element) {
      var sectionHeight = element.prop('scrollHeight'),
        scrollTop = element.offset().top,
        headerHeight = getHeaderHeight();
      element.animate({
        height: sectionHeight
      }, 200, function () {
        parent.addClass("readmore--open");
        $("html, body").animate({
          scrollTop: scrollTop - parseInt(headerHeight)
        }, 300);
      });
    }
    function editModeEventsForReadmore() {
      if (isInEditMode) {
        $(".text-image-spot-readmore-open, .text-image-spot-readmore-less, .text-image-spot-readmore-close").click(function (e) {
          e.preventDefault();
        });
      }
    }
    function getHeaderHeight() {
      if ($(".header-desktop-top").is(":visible")) {
        return $(".header-desktop-top").height();
      } else {
        return $(".header-mobile-top").height();
      }
    }
  });
})(jQuery);
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('#content .component.text-image-spot').addClass('version2'); // TO BE USED WHEN version2 IS RELEASED AS ONLY SOLUTION
    $('#content .component.text-image-spot.version2.tforceheight').each(function () {
      var $spot = $(this);
      $('.text-image-spot-image-container', $spot).each(function () {
        var $container = $(this),
          $img = $('img', $container),
          src = resolveImageSrc($img.length ? $img[0] : null);
        $container.css('background-image', "url('" + src + "')");
      });
    });
  });
})(jQuery);
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    $('#content .component.text-image-spot').each(function () {
      var $spot = $(this),
        $initial = $(".text-image-spot-image img"),
        $initialSrc = $initial.attr("src"),
        $imageList = $('.text-image-spot-image-elements', $spot),
        $images = $(".text-image-spot-element img", $imageList),
        $link = $(".text-image-spot-image a", $spot),
        $initialLink = $link.attr("href"),
        $mainImgContainer = $(".text-image-spot-image-container", $spot),
        $mainTxtContainer = $(".text-image-spot-text-container", $spot),
        addSlider = $images.length > 4 ? true : false,
        $slickList = $imageList,
        selectedClass = "selected",
        addSlick = function addSlick() {
          // REVERSES ORDER BECAUSE SLICK DOESN'T FLOAT THE ELEMENTS RIGHT AS THE STYLING IS WHEN
          // THERE'S FEWER THAN 5 IMAGES. SO IF THIS IS NOT DONE IT IS PERCIEVED AS IF IT'S REVERSED
          $slickList.children().each(function (i, elem) {
            $slickList.prepend(elem);
          });
          $slickList.slick({
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 3
          });
        },
        addToggleMainImageAction = function addToggleMainImageAction() {
          var $mainImg = $(".text-image-spot-image-container .text-image-spot-image > a > img, .text-image-spot-image-container .text-image-spot-image > img", $spot);
          $images.click(function () {
            console.log($link);
            var $img = $(this),
              thisSrc = $img.attr("src");
            var linkSrc = $link.attr("href");
            //switch src on main image
            $mainImg.fadeOut(10, function () {
              $mainImg.attr("src", thisSrc);
              if ($mainImg.attr("src") === $initialSrc) {
                $link.attr("href", $initialLink);
              } else {
                $link.attr("href", "#");
              }
            }).fadeIn(100);

            //move small image to end of list - not part of the req, hence commented out for now
            if (!addSlider) {
              //$img.closest(".text-image-spot-element").prependTo($img.closest(".text-image-spot-image-elements"));
            }
          });
        };
      if ($imageList.length > 0) {
        $spot.addClass("multiple-images");
        if ($spot.hasClass("version2")) {
          $imageList.appendTo($mainImgContainer);
        } else {
          $imageList.appendTo($(".text-image-spot-image-container .text-image-spot-image", $spot));
          $spot.css("padding-bottom", $imageList.height());
        }
        if (addSlider) {
          addSlick();
        }
        // Attach the click events
        $("img", $imageList).on("click", function () {
          $("img", $imageList).removeClass(selectedClass);
          $(this).addClass(selectedClass);
        });

        // Set up default image selector
        $images.each(function () {
          if ($(this).attr("src") === $mainImgContainer.find("img").attr("src")) {
            $(this).addClass(selectedClass);
          }
        });

        //only necessary on medium and up
        if (!window.matchMedia('(max-width: 768px)').matches) {
          resizeImageList($spot, true);
        }
        addToggleMainImageAction();
      }
    });
    function resizeImageList(spot, resize) {
      var $spot = spot;
      var $imageList = $('.text-image-spot-image-elements', $spot);
      if (resize) {
        var $mainImgContainer = $(".text-image-spot-image-container", $spot),
          $mainImgWidth = $('img', $mainImgContainer).width();
        $imageList.width($mainImgWidth - 40);
      } else {
        $imageList.css("width", "auto");
      }
    }
    DGS.Resizer.getInstance().register(function () {
      $('#content .component.text-image-spot.multiple-images').each(function () {
        var $spot = $(this);
        if (window.matchMedia('(max-width: 768px)').matches) {
          resizeImageList($spot, false);
        } else {
          resizeImageList($spot, true);
        }
      });
    });
  });
})(jQuery, window.DGS);